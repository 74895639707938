/* Contact Form */
$placeholder: $primary;
$input-height: 50px;
$input-background:transparent;
$input-font-size:16px;
$input-font-color: #414656;
$input-border: solid 1px $input-font-color;
$input-border-hover: $accent;
$label-color:black;
$input-color:$input-font-color;
$border-radius: 0px;


input#preferredKitten { display: none; }

#thank-you {
  display: none;
}

.form-item {
  margin-bottom: 30px;
}
.main-form {
  * {
    outline: none !important;
  }
  position: relative;
  &:before,&:after {
    content: " "; display: table; 
  }
  &:after{
    clear: both; 

  }
  label {
    display: block;
    padding: 10px 5px 5px 2px;
    font-size: 1.1em;
    letter-spacing: 1px;
    cursor: pointer;
    font-family: $heading;
    color:$label-color;
    font-weight: 600;
  
  }
  input,textarea,select {
    font-family: $body;
    font-weight: 100;
    line-height: 1.5;
    border-radius:$border-radius;
    font-size: $input-font-size;
    padding: 0 0 0 20px;
    color: $input-color;
    display: block;
    width: 100%;
    background: $input-background;
    height:$input-height;
    border:$input-border;
    @include placeholder($placeholder);
    @include transition(.3s all);
    margin-bottom: .5em;
    &:focus {
      border-color:$input-border-hover;
    }
  }
  fieldset {
    input {
      margin-bottom: .5em;
    }
  }
  input[type="file"]{
    padding:10px 0 10px 20px;
  }
  select {
    background-position: right 50%;
    background-repeat: no-repeat;
    //background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFW…zTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
    padding: .5em;
    border-radius:0;
  }
  textarea {
    min-height: 200px;
    padding: 20px;

  }
  .button {
    @extend .button !optional;
    height:auto !important;
    width:100%;
  }
.fIrSTnaME {
    position: absolute;
    left: -9999px;
    display: block;
  }

}



.thumbs {
  width:47%;
  margin:1%;
  float:left;
  img {
    width:100%;
  }
}