//@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700,800|Oswald:400,700');
@import "../../node_modules/bootstrap/scss/bootstrap";
@import 'application/variables';
@import 'application/mixins';
@import 'application/typography';
@import 'application/forms';


header {
  position: absolute;
  widtH: 100%;
  z-index: 100;
  padding-top: 2rem;

  #branding {
    &:after {
      content: "";
      width: 2.857rem;
      margin: 1.7rem 0;
      display: block;
      border-top: 0.07143rem solid #969696;
    }
  }

  .definition {
    font-family: Verdana, Helvetica, Arial, sans-serif;
    font-size: 0.7857rem;
    color: $white;
    transform: rotateZ(90deg);
    transform-origin: top left;
    position: absolute;
    left: 2.214rem;

  }

  #navigation {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button, span {
      width: 1.643rem;
    }

    button {
      background: none;
      border: none;
      height: 2rem;
      position: relative;
      cursor: pointer;

      span {
        display: block;
        background-color: $white;
        height: 0.25rem;
        margin: 3px 0;

        &:first-child, &:last-child {
          margin: 0;
        }

        &:last-child {
          width: 0.9286rem;
          position: relative;
          left: 0.718rem;
        }
      }

      &:focus {
        box-shadow: none !important;
        outline: none !important;
      }
    }
  }
}

#mad-libs {
  text-align: center;
  padding: 6rem 0;
  color: $white;
  position: relative;
  overflow: hidden;
  @include media-breakpoint-up(md){
    padding:18rem 0;
  }
  .container-fluid {
    position: relative;
    z-index: 2;
  }
  p {
    color:white;
  }
  .media {
    position: absolute;
    width: 130%;
    height: 100%;
    left: -15%;
    top: 0;
    z-index: 1;
    video {
      width: 100%;
      height: auto;
      top: 50%;
      position: relative;
      transform: translateY(-50%);
    }
  }

  h1 {
    font-size: 1.75rem;
    color:$yellow;
    font-weight: 800;
    span {
      color:white;
    }
    @include media-breakpoint-up(md){
      font-size: 5rem;
    }

  }

  h2 {
    //color: $white;
  }

  p {
    margin: 0;
    padding: 0;

    &#actual {
      display: inline-block;
      color: $accent;
      font-size: 70px;
      font-weight: 700;
      line-height: 1.1;
      text-transform: uppercase;
      margin-bottom: 15px;
      font-family: $heading;
      position: relative;
          @include media-breakpoint-down(md){
      font-size: 30px;
    }
      span {
      animation: blink-caret .75s step-end infinite;
      font-weight: 100;
      font-family: $body;
      position: relative;
      right: 2%;
      display: inline-block;
      transform:translateY(-11%);

      }
    }
    &#pretend {
      font-size: 1.5rem;
    }

  }
}

.portfolio-item{
  background-size: cover;
  min-height:500px;
  display: flex;
  justify-content:center;
  position: relative;
  align-items:center;
  height:100%;
  background-position: center;
  &:hover {
    .portfolio-meta {
      opacity:1;
      .portfolio-content {
      transform:translateY(0px);

      }
    }
  }
  .portfolio-meta {


    padding:2em;
    height:100%;
    width:100%;
    position: absolute;
    top:0;
    left:0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include transition(.3s all);
    h3 {
      margin-bottom: 16px;
      font-weight: 800;
      color:$accent;
      text-transform: uppercase;
    }
    a {
      color:white;
    }
    .portfolio-content {
      transform:translateY(10px);
    @include transition(.6s all);
    position: relative;

    }
    h3,a {
      position: relative;
    display: block;
  
    }
    background: rgba(black,.8);
    opacity:0;
  }
}

#definition, #process, #work, #contact, #skills {
  padding: 7rem 0;
}

#skills {
  * {
    color:white;
  }
}

.headline-container {
  @include media-breakpoint-up(md){
    padding:0 4em;

  }

  .section-headline {
    //font-size: 5rem;
    font-size: 11vw;
    line-height: 1;
    margin-top:-1vw;
    font-weight: 800;
    font-family: $heading;
    text-transform: uppercase;
    color:$dark-grey;
  }
}

.gradient {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#4c5767+0,32363e+100 */
background: #4c5767; /* Old browsers */
//background: -moz-radial-gradient(center, ellipse cover, #4c5767 0%, #32363e 100%); /* FF3.6-15 */
//background: -webkit-radial-gradient(center, ellipse cover, #4c5767 0%,#32363e 100%); /* Chrome10-25,Safari5.1-6 */
background: radial-gradient(ellipse at center, #4c5767 0%,#32363e 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c5767', endColorstr='#32363e',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

#definition {
  .positioning-statement {
 

    h1 {
      color:white
    }
  }
  color: $white;
  background-color: $dark-grey;
  // background-image: url('../img/bg.jpg');
  display: flex;
  justify-content: center;
  align-items: center;
  padding:5rem 0;
  background-size: cover;


  .dictionary {

    p {
      font-size: 16px;
    }
    h2 {
      text-transform: none;
      font-weight: 100;
      strong {
        font-weight: 800;
      }
      span {
        font-size: 50%;
        font-style: italic;
        text-transform: none;
        color: $accent;
      }
    }
  }
  h2 {
    font-size: 3rem;
    color:white;
    margin-bottom: 0;

  }
      h3 {
      color:$accent;
    }
    .headline-container {
        p {
    font-style: italic;
    strong {
      font-style: normal;
      text-transform: uppercase;
    }
  }
    }

}

#process {
  background-color: $accent;
  color: $black;

  .process-item {
    margin-bottom: 2em;
  }

  h1,h2,h3 {
    color:$dark-grey;
  }

  h1 {
    font-size: 3rem;
    margin:0 0 16px;
  }

  a {
    color: $white;
  }

  h4 {

  }
}

#work {
  background-color: $white;
  padding:0;
  .headline-container {
    padding-top:3em;
    padding-bottom: 3em;
        @include media-breakpoint-down(md){
      padding:3em 15px;
    }
  }
  .container-fluid {
    padding: 0;
  }
}

#contact {
  background-color: $white;

  h2, h3, p {
    color: $dark-grey;
  }

  h3 {
    font-weight: 300;
    display: inline-block;
  }
}

#contact {
  h2, h3 {
    display: block;
  }

  .headline-container {
    h2 {
      font-size: 9vw;
    }
  }

  .form-section {
    margin: 2rem -15px;
    &:first-child {
      margin:0 -15px;
    }
  }

  .form-legend {
    color: $dark-grey;
    font-size: 2.125rem;
    font-weight: 700;
    text-transform: uppercase;
  }

/*   #timelineRange, #budgetRange {
    margin-top: 0.6rem;
    list-style-type: none;
    display: table;
    width: 100%;

    .date-range, .budget-range {
      cursor: pointer;
      display: table-cell;
      text-align: center;

      span {
        font-weight: 700;
        text-transform: uppercase;

        &:after {
          content: "\A";
          white-space: pre;
        }
      }
    }
  } */
}

footer {
  padding:4em 0;

    a {
      color:$accent;
      &:hover {
        color:white;
      }
    }
  #copyright-legal {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: $white;
    opacity: 0.6;
    font-size: 0.7143rem;


    p {
      margin: 0.2143rem 0 0 0;
      padding: 0;
    }
  }

  #social-lynx {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        margin: 0 0.7143rem;
        a {
          font-size: 1.25rem;
        }
      }
    }
  }
}

#timeline-range, #budget-range {
  margin-top: 0.6rem;
  list-style-type: none;
  display: table;
  width: 100%;

  .date-range, .budget-range {
    cursor: pointer;
    display: table-cell;
    text-align: center;

    span {
      font-weight: 700;
      text-transform: uppercase;

      &:after {
        content: "\A";
        white-space: pre;
      }
    }
  }
}


.noUi-target {
  background-color: #fafafa;
}

.noUi-background {
  background-color: #fafafa;
}

.noUi-connect {
  background-color: #4a4a4a;
}



// Small devices (landscape phones, 576px and up) ========================================= //
@media (min-width: 576px) {

}

// Small devices (tablets, 768px and up) ========================================= //
@media only screen and (min-width: 768px) {

}

// End Small devices (tablets, 768px and up) ===================================== //

// Medium devices (desktops, 992px and up) ======================================= //
@media only screen and (min-width: 992px) {

}

// End Medium devices (desktops, 992px and up) ==================================== //

// Large Medium devices (desktops, 1200px and up) ================================= //
@media only screen and (min-width: 1200px) {

}

// End Large Medium devices (desktops, 1200px and up) ============================= //

@keyframes blink-caret {
  from, to {
    color: transparent
  }
  50% {
    color: $accent;
  }
}