// Colors
// ------------------------
$dark-grey: #424242;
$light-grey: #5e5e5e;
$black: $dark-grey;
$white: #fff;
$grey: #999;
$accent: #ecbc17;
$error: #c62f28;
$success: #84c02f;
$primary: $dark-grey;

// Paths
// ------------------------

// Layout
// ------------------------
$input-placeholder-opacity: 0.5;

$heading: 'Oswald', sans-serif, Helvetica, Arial, sans-serif;
$body: 'Open Sans', sans-serif, Helvetica, Arial, sans-serif;