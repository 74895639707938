body, html {
  font-family: $body;
  @include media-breakpoint-up(md){
    font-size: 1rem;
  }
  font-size: .75rem;
  background-color: #616161;
  color: $dark-grey;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: "liga", "kern";
  text-rendering: optimizelegibility;
}

h1, h2, h3, h4, h5 {
  font-family: $heading;
  color: $dark-grey;
  margin: 0 0 20px;
  text-transform: uppercase;
  font-weight: 800;

}



p,li {
 font-family: $body;
  font-size: 1.25rem;
  line-height: 1.4;
  font-weight: 100;

}
p {
  strong,b{
    font-weight: 600;
  }
  a {
    font-weight: 600;
  }
  margin-bottom: 1em;

}


p a {
	color:$accent;
	font-weight: 800;
	&:hover {
		color:$dark-grey;
		text-decoration: none;
	}
}


a {
	@include transition(.3s all);
}


.button {
	display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    outline:0 !important;
	background-color: $accent;
    padding: 1rem 2.5rem;
    font-size: 1.5rem;
    text-transform: uppercase;
    line-height: 1.5;
    margin:1rem 0;
    border-radius:0;
    font-family: $heading;
    color:$dark-grey;
    cursor:pointer;
    font-weight: 800;
    @include transition(.3s all);
    &:hover {
    	background-color: $dark-grey;
    	color:white;
    }
}
